
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { FacebookMessengerPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type FacebookSettings = Pick<
  FacebookMessengerPlatformData,
  'enablePosting' | 'pageId' | 'pageAccessToken' | 'pageName'
>;

@Component({
  name: 'facebook-settings-card',
})
export default class FacebookSettingsCard extends mixins(BotMixin) {
  facebookSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.facebookSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.FacebookMessenger,
        data: this.facebookSettings,
      });
      this.$notify.success('Successfully updated Facebook-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Facebook-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.facebookSettings = this.resetValue();
  }

  private resetValue(): FacebookSettings {
    return {
      enablePosting: this.bot.platformData.facebookMessenger.enablePosting,
      pageId: this.bot.platformData.facebookMessenger.pageId,
      pageAccessToken: this.bot.platformData.facebookMessenger.pageAccessToken,
      pageName: this.bot.platformData.facebookMessenger.pageName,
    };
  }
}
